var swiper__headerHome = new Swiper('.swiper__headerHome', {
    speed: 400,
    spaceBetween: 0,
    loop: true,
    autoplay: {
        delay: 5000,
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
});

var swiper__featuredProducts = new Swiper('.swiper__featuredProducts', {
    slidesPerView: 'auto',
    centeredSlides: true,
    loop: true,
    loopedSlides: 2,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    autoplay: {
        delay: 5000,
    },
});

$('.c-linkScrollDown a').on('click', function(e) {
    e.preventDefault();
    var finalDestiny = $(this).attr('href');
    goToSection__scroll(finalDestiny, 50, 50, 700, 10);
});